import { ZigTheme } from "../types";

const light: ZigTheme = {
  mode: "light",
  fontFamily: ["Avenir Next", "Roboto", "Helvetica", "Arial", "sans-serif"],
  // having this key is important
  // without it overriding would not work
  fontFamilyH1H6: undefined,
  palette: {
    neutral900: "#CFD8DC", // header table and highlighted BG color
    neutral800: "#90A4AE", // menu main BG color
    neutral750: "#B0BEC5", // secondary menu BG and selected menu item
    neutral700: "#90A4AE", // division table lines color and hover
    neutral600: "#BBDEFB", // markplace arrow color and some borders
    neutral500: "#546E7A", // N/A
    neutral400: "#1f1f1f", // auxiliar texts color
    neutral300: "#37474F", // highlighted texts color
    neutral200: "#263238", // menu and table header text color
    neutral175: "#90CAF9", // auxiliar button text color
    neutral150: "#E1E9F0", // arrow color and sub button text color
    neutral100: "#263238", // header text color
    neutral000: "#CFD8DC", // button text color
    secondary: "#FFFFFF",
    highlighted: "#0D47A1",
    red: "#EF5350",
    criticalRed: "#D32F2F",
    green: "#26A69A",
    links: "#2196F3",
    yellow: "#C0CA33",
    contrasting: "#F3F4F6",
    lightGrey: "#9ca3af",
    paleBlue: "#999fe1",
  },
  backgrounds: {
    body: `#fefeff`,
    header: "linear-gradient(269.14deg, #E0E0E0 0%, #ECEFF1 100%)",
    neutralBg: "#F5F5F5",
    buttonPrimary: "#2196F3",
  },
  imageColorOverride: "",
};

export default light;
